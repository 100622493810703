<template>
  <div id="vod-view">
    <el-form label-width="80px">
      <el-form-item label="fileId">
        <el-input v-model="fileId" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="预览">
        <div class="video-container">
          <div class="top-player">
            <video
              id="player-container-id"
              preload="auto"
              playsinline
              webkit-playsinline
            ></video>
            <div class="test">
              <el-input
                v-model="fileId"
                :disabled="true"
                style="width: 200px"
              ></el-input>
              <el-button type="primary" style="width: 200px" @click="onFull()"
                >可能的写弹幕区域</el-button
              >
            </div>
          </div>
        </div>
      </el-form-item>
    </el-form>

    <!-- <el-button size="small" @click="onFull()">full screen</el-button> -->
  </div>
</template>

<script>
//import { alertMessage } from "@/util/alertMessageUtil.js";
import { vodApi } from "@/api/vodApi";

export default {
  components: {},
  data() {
    return {
      fileId: "",
      vodParam: {},
      urlWithKey: "",
      player: null,
    };
  },
  mounted: function() {
    this.fileId = this.$route.params.id;
    if (!this.fileId) {
      return;
    }

    vodApi
      .getVodParam(this.fileId)
      .then((ret) => {
        let { code, data } = ret;
        if (code == 0) {
          this.vodParam = data;
          console.log(this.vodParam);
        }
      })
      .then(() => {
        let _this = this;
        this.player = new this.$TcPlayer("player-container-id", {
          // player-container-id 为播放器容器ID，必须与html中一致
          fileID: _this.vodParam.fileID, // 请传入需要播放的视频filID 必须
          appID: _this.vodParam.appID, // 请传入点播账号的appID 必须
          // t: _this.vodParam.t,
          // us: _this.vodParam.us,
          // sign: _this.vodParam.sign,
          psign: _this.vodParam.psign,
        });
      });

    this.$nextTick(() => {
      // let videoPlayer = document.getElementsByClassName("video")[0];
      // const w = videoPlayer.offsetWidth;
      // const h = videoPlayer.offsetHeight;
      // let container = document.getElementsByClassName("danmu-container")[0];
      // container.style.width = w;
      // container.style.height = h;
      // let ele = document.getElementsByClassName("danmu")[0];
      // const x = w;
      // ele.style.transform = `translateX(${x}px)`;
      this.onGetVodUrl(this.fileId);
    });
  },
  destroyed() {
    if (this.player) {
      this.player.dispose();
    }
  },

  methods: {
    onGetVodUrl(fileId) {
      vodApi.getVodUrl(fileId).then((ret) => {
        console.log(ret);
      });
    },
    onFull() {
      let container = document.getElementsByClassName("video-container")[0]; //video-container
      if (!document.fullscreenElement) {
        container.requestFullscreen();
        let ele = document.getElementById("player-container-id");
        const clientHeight = document.body.clientHeight;
        const clientWidth = document.body.clientWidth;
        const offsetHeight = document.body.offsetHeight;
        const offsetWidth = document.body.offsetWidth;
        // alert(
        //   clientHeight +
        //     ";" +
        //     clientWidth +
        //     ";" +
        //     offsetHeight +
        //     ";" +
        //     offsetWidth
        // );

        ele.style.width = clientWidth + "px";
        ele.style.height = clientHeight + "px";
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          let ele = document.getElementById("player-container-id");
          ele.style.width = 600 + "px";
          ele.style.height = 400 + "px";
        }
      }
      // const li = document.createElement("div"); //创建li标签
      // li.className = "top-class";
      // li.innerHTML = "item";
      // document.getElementsByClassName("video-container")[0].appendChild(li);
    },
  },
  watch: {},
};
</script>

<style>
.top-class {
  position: absolute;
  z-index: 10000;
  top: 0px;
  left: 0px;
}
</style>

<style lang="less" scoped>
#vod-view {
  margin: 20px;
  width: 60%;
  min-width: 600px;
  .video {
    width: 100%;
  }
  .video-container {
    width: 800px;
    height: 600px;
    display: flex;
    flex-direction: column;
    .top-player {
      #player-container-id {
        width: 600px;
        height: 400px;
      }
      .test {
        position: absolute;
        bottom: 32px;
        left: 30%;
        z-index: 100;
      }
    }
  }
  .danmu-container {
    position: absolute;
    z-index: 100;
    top: 0px;
    left: 0px;
    height: 20px;
    width: 100%;
    //background: rgba(100, 100, 100, 0.5);
    .danmu {
      //border: 2px solid red;
      //margin-bottom: 20px;
      //transform: translateX(600px);
      animation: scrollTo linear 4s infinite;
    } // 弹幕盒子的样式
  }
}

@keyframes scrollTo {
  to {
    transform: translateX(00px);
  }
}
</style>
